<template>
  <div>
    <div class="company-wrapper company-wrapper-skeleton" v-if="promoPageListLoading">
      <mu-circular-progress
        class="demo-circular-progress"
        :size="26"
      >
      </mu-circular-progress>
    </div>
    <div class="company-wrapper" v-if="promoPage.id !== null && !promoPageListLoading">
      <company-head-nav :leftNav="true"
                        @closeItem="close()"
                        @promoFavoriteCompanyEvent="toggleFavFromCompanyHeadNav"
                        :isFavoriteCompany="promoPage.is_favorite"
                        :video_stream_url="promoPage.video_stream_url"
                        @enable-fullscreen-mode="enableFullscreenMode"
      ></company-head-nav>

      <div :class="{'overlay overlay-visible': fullscreenMode}">
        <div class="close-icon" @click="enableFullscreenMode(false)">
          <iconZoomOut :color="'#fff'"></iconZoomOut>
        </div>
        <company-media-block
          :external-id="promoPage && promoPage.external_id"
        ></company-media-block>
      </div>

      <div class="company">
        <div class="head-block">
          <div class="avatar">
            <img :src="setCompanyAvatar(promoPage.logo_url)" alt="image"/>
          </div>
          <div class="title">
            <span class="inline-block max-width">{{ truncatedText(promoPage.title, 75) }}</span>
            <div class="location" v-if="promoPage.country && promoPage.city">
              {{ promoPage.city.name }}, {{ promoPage.country.name }}
            </div>
            <div class="website" v-if="promoPage.website"><a :href="promoPage.website" target="_blank">{{promoPage.website}}</a></div>
          </div>
        </div>
        <div class="nav">
        <span class="tab cur-p"
              :class="{'highlight': navSelected === 'info'}"
              @click="navSelect('info')">{{ $t('title.info') }}</span>
          <span class="tab cur-p"
                :class="{'highlight': navSelected === 'products'}"
                @click="navSelect('products')">{{ $t('title.products') }}</span>
          <span class="tab cur-p"
                :class="{'highlight': navSelected === 'team'}"
                @click="navSelect('team')">{{ $t('title.team') }}</span>
          <span class="tab cur-p"
                :class="{'highlight': navSelected === 'documents'}"
                @click="navSelect('documents')">{{ $t('title.documents') }}</span>
          <span class="tab cur-p"
                :class="{'highlight': navSelected === 'notes'}"
                @click="navSelect('notes')">{{ $t('title.notes') }}</span>
        </div>
        <div class="content" v-if="navSelected === 'info'">
          <div :class="{'truncated-text': false}" class="description ws-pre-wrap" v-if="promoPage.description">
            <truncate action-class="read-more"
                      :length="180"
                      :less="$t('button.less')"
                      :clamp="$t('button.more')"
                      type="html"
                      :text="generateLinks(promoPage.description)"></truncate>
          </div>
        </div>
      </div>

      <!-- Product list -->
      <div class="field-block field-block-products" v-if="navSelected === 'info' || navSelected === 'products'"
           :class="{
              'field-block-products-short' : navSelected !== 'products',
              'field-block-products-full' : navSelected === 'products'
           }"
      >
        <div class="field-header">
          <div class="title">{{ $t('title.products') }}</div>
          <div class="action" v-if="navSelected !== 'products'">
            <a class="btn cur-p" @click="showAllProducts()">{{
                $t('button.seeAll')
              }}</a>
          </div>
        </div>
        <div class="field-content">
          <div class="content">
            <products-list :products="promoPage.products"
                           :companyName="promoPage.title"
                           :showAll="navSelected === 'products'"
                           :class="{
              'products-list-short' : navSelected !== 'products',
              'products-list-full' : navSelected === 'products'
           }"
            ></products-list>
          </div>
        </div>
      </div>

      <!--  ---  -->
      <div class="field-block" v-if="navSelected === 'info' || navSelected === 'team'">
        <div class="field-header">
          <div class="title">{{ $t('title.team') }}</div>
          <div class="action" v-if="navSelected === 'info'">
            <a class="cur-p btn" @click="showAllTeam()">{{ $t('button.seeAll') }}</a>
          </div>
        </div>
        <div class="field-content enumerable cur-p" :class="{'show-all': showTeam}">
          <div class="content-item team-member"
               v-for="(item, key) in teamMembers"
               :key="key"
               v-show="key < 4 || showTeam"
               @click="showProfile(item.contact.id, item.contact.user.id)"
               :class="{'selected': item.contact.id == contact_id}"
          >
            <div class="avatar round-radius">
              <img
                :src="setAvatar(item.contact.photo_url)"
                alt="img"
              />
            </div>
            <div class="team-member-info" v-if="item.displayTexts">
              <div class="team-member-name-part" v-if="item.displayTexts.name || item.displayTexts.surname">
                <div class="team-member-name" v-if="item.displayTexts.name">{{ item.displayTexts.name }}</div>
                <div class="team-member-surname" v-if="item.displayTexts.surname">{{ item.displayTexts.surname }}</div>
              </div>
              <div class="team-member-job-part" v-if="item.displayTexts.position || item.displayTexts.company">
                <div class="team-member-position" v-if="item.displayTexts.position">{{ item.displayTexts.position }}</div>
                <div class="team-member-company" v-if="item.displayTexts.company">{{ item.displayTexts.company }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Documents -->
      <document-list
        class="field-block field-block-documents"
        :class="{
            'show-action': navSelected === 'info',
            'show-all': navSelected === 'documents'
          }"
        :docs="promoPage.files"
        :showAll="navSelected === 'documents'"
        @seeAllDocuments="navSelect('documents')"
        v-if="promoPage.files && promoPage.files.length && (navSelected === 'info' || navSelected === 'documents')"
      ></document-list>


      <!-- Notes -->
      <notes-list
        class="field-block field-block-notes"
        v-if="noteList && noteList.length && (navSelected === 'info' || navSelected === 'notes')"
        :notes="noteList"
        :showAll="navSelected === 'notes'"
        :showActionButton="navSelected !== 'notes'"
        @seeAllNotes="navSelect('notes')"
      ></notes-list>


    </div>
<!--    <div class="overlay" :class="{'overlay-visible': fullscreenMode}">-->
<!--      <div class="close-icon" @click="enableFullscreenMode(false)">-->
<!--        <iconZoomOut :color="'#fff'"></iconZoomOut>-->
<!--      </div>-->
<!--      <company-media-block-->
<!--        :files="promoPage.photos"-->
<!--        :video-stream-embed="promoPage.video_stream_embed"-->
<!--      ></company-media-block>-->
<!--    </div>-->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import companyHeadNav from './../companies/company-head-nav'
import truncate from 'vue-truncate-collapsed';
import productsList from '../../products/productList';
import { FileNameHelper } from '@/helpers/filename.helper';
import DocumentList from '../documents/documentList';
import NotesList from '../notes/notesList';
import Statistics from '@/services/statistics';
import iconZoomOut from '@/_modules/icons/components/icon-zoom-out.vue';
// import axios from 'axios';
// import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
// import MeetingRoomsHelper from '@/_modules/meeting-rooms/helpers/meeting-rooms.helper';
import CompanyMediaBlock from '@/_modules/promo/components/company-media-block/company-media-block.vue';
import ChatHelper from "@/_modules/chat/helpers/chat.helper";

// const BROADCASTS_CHECK_INTERVAL = 5000;

const beforeRouteEnter = { to: null, from: null };

export default {
  name: 'promo-contact-company',

  components: {
    companyHeadNav,
    truncate,
    productsList,
    DocumentList,
    NotesList,
    iconZoomOut,
    CompanyMediaBlock,
  },

  computed: {
    ...mapState({
      promoOwnerId(state) {
        if (this.event_id) {
          this.$store.dispatch('noteStore/callNoteListAllPromoPage',
            {
              event_id: Number(this.event_id),
              user_id: state.promoStore.promoOwnerId,
            }
          );
        }

        // this.$store.dispatch('_messageStore/callMessageListPromoPageAll',
        // 	{
        // 		event_id: this.event_id,
        // 		user_id: state.promoStore.promoOwnerId
        // 	});

        this.user_id = state.promoStore.promoOwnerId;
        this.setMessages();

        return state.promoStore.promoOwnerId;
      },
      promoPage(state) {
        this.external_id = state.promoStore.promoPage.external_id;
        this.isFavoriteCompany = state.promoStore.promoPage.is_favorite;
        return state.promoStore.promoPage;
      },
      noteList(state) {
        return state.noteStore.noteListPromoPage.List
      },
      // messageListPromoPageAll: state => state._messageStore.messageListPromoPage.List,
      promoPageListLoading: state => state.promoStore.promoPageListLoading,
      attachedContacts: state => state.promoStore.promoPage.attached_contacts,
    }),
    ...mapGetters({
      user_info: '_userStore/user',
      messageListPromoPageAll: '_messageStore/messages',
    }),
    teamMembers() {
      if (!this.promoPage || !this.promoPage.attached_contacts) {
        return [];
      }
      return this.promoPage.attached_contacts.map(member => {
        return {
          ...member,
          displayTexts: {
            name: member.contact && member.contact.name ? this.truncateMemberInfoString(member.contact.name, 10) : '',
            surname: member.contact && member.contact.surname ? this.truncateMemberInfoString(member.contact.surname, 10) : '',
            position: member.contact && member.contact.company_position ? this.truncateMemberInfoString(member.contact.company_position, 10) : '',
            company: member.contact && member.contact.company_name ? this.truncateMemberInfoString(member.contact.company_name, 10) : '',
          }
        }
      });
    },
  },

  beforeRouteEnter(to, from, next) {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  },

  created() {
    this.event_id = Number(this.$route.params.eventId);
    this.contact_id = this.$route.params.contact_id;
    // this._startBroadcastsCheck();
  },
  // beforeDestroy() {
  //   this._stopBroadcastsCheck();
  // },
  watch: {
    external_id: {
      immediate: true,
      handler() {
        Statistics.promopageView({
          eventId: this.event_id,
          externalId: this.external_id,
          contactId: this.contact_id,
        }, beforeRouteEnter);
      }
    },
    isShowTeam: {
      handler() {
        this.showTeam = this.isShowTeam;
      }
    },
    isShowDocuments: {
      handler() {
        this.showDocuments = this.isShowDocuments;
      }
    },
    isShowNotes: {
      handler() {
        this.showNotes = this.isShowNotes;
      }
    },
    isFavoriteCompany: {
      immediate: true,
      handler() {
        this.$emit('promoCallFavCompany', this.isFavoriteCompany)
      }
    }
  },
  data() {
    return {
      list: null,
      noteFav: true,
      showMore: false,
      more: this.$t('title.documents'),
      showTeam: false,
      showDocuments: false,
      showNotes: false,
      event_id: null,
      external_id: null,
      company_index: null,
      isFavoriteCompany: false,
      showMoreId: null,
      text: '',
      user_id: null,
      messageText: '',
      navSelected: 'info',
      contact_id: null,
      fullscreenMode: false,
      // broadcasts: [],
      // isBroadcastsCheckEnabled: true,
    };
  },
  methods: {

    /* Duplicated from edit-company.vue TODO: code reuse (i.e. via helper) */
    truncateMemberInfoString(input, toLength) {
      if (input && input.length > toLength) {
        const truncatePostfix = '…';
        input = input.substr(0, toLength) + truncatePostfix
      }
      return input;
    },

    setAvatar(url) {
      let noPhoto;
      if (url) {
        return url
      } else {
        noPhoto = require('../../../../assets/images/no-avatar-60x60.svg');
      }
      return noPhoto;
    },
    setCompanyAvatar(url) {
      let noPhoto;
      if (url) {
        return url
      } else {
        noPhoto = require('../../../../assets/images/no-avatar-company-60x60.svg');
      }
      return noPhoto;
    },
    docName(val) {
      return FileNameHelper.getFileName(val);
    },
    docType(val) {
      return FileNameHelper.getFileExtension(val);
    },
    showAllTeam() {
      this.showTeam = !this.showTeam;
      this.navSelect('team');
      // this.$emit("promoShowTeam", this.showTeam);
    },
    showAllProducts() {
      this.showProducts = !this.showProducts;
      this.navSelect('products')
    },
    showAllDocuments() {
      this.showDocuments = !this.showDocuments;
      this.navSelect('documents');
      // this.$emit("promoShowDocuments", this.showDocuments);

    },
    showProfile(contact_id, user_id) {
      if (contact_id) {
        if (Number(contact_id) !== Number(this.contact_id) &&
          Number(user_id) !== Number(this.user_info.id)) {

          this.$router.push({name: 'promo-page-contacts-contact', params: {contact_id: contact_id}}).catch(() => {
          });
        }
      }
      // this.$emit("promoShowProfile", true, key) // rewrite to routes and this will works
    },
    showAllNotes() {
      this.showNotes = !this.showNotes;
      this.navSelect('notes');
      // this.$emit("promoShowNotes", this.showNotes);
      if (this.event_id) {
        this.$store.dispatch('noteStore/callNoteListAll',
          {
            event_id: this.event_id,
          })
      }
    },
    async setMessages() {
      if (this.event_id && this.user_id) {

        await this.$store.dispatch('_messageStore/callMessageList',
          {
            eventId: this.event_id,
            userId: this.user_id,
          })

        if (this.messageListAll && this.messageListPromoPageAll.List) {
          this.messageList = this.messageListPromoPageAll.List
        }
      }
    },
    sendMessage() {
      if (this.messageText && this.messageText.trim()) {
        Promise.all([
          this.$store.dispatch('_messageStore/sendMessage',
            {
              eventId: this.event_id,
              userId: this.user_id,
              text: this.messageText.trim()
            })
        ]).then(() => {
          this.setMessages();
          this.messageText = '';
        });
      }
    },
    close() {
      this.$store.dispatch('promoStore/clearPromoPageContactId')
    },
    toggleFavFromCompanyHeadNav(bool) {
      this.isFavoriteCompany = bool;
      let payload = {
        event_id: this.event_id,
        external_id: this.external_id
      };

      if (!bool) {
        this.$store.dispatch('promoPageStore/removeFavorite', payload);
      } else {
        this.$store.dispatch('promoPageStore/addFavorite', payload);
      }
      // this.$emit('promoCallFavCompany', bool)
    },
    goToOwnerMessages() {
      // let ownerKey;
      // this.promo-page.attached_contacts.forEach((item, index) => {
      // 	if (item.role === 'owner') {
      // 		ownerKey = index;
      // 	}
      // });
      // this.$emit('promoProfileNavSelected', 'messages');
      // this.showProfile(ownerKey);
      // this.setMessages()

      this.navSelect('messages')
    },
    navSelect(value) {
      this.navSelected = value || 'info';
    },
    setName(name) {
      if (name) {
        let nameArr = name.split(' ');
        return nameArr[0];
      }
    },
    setSurnameName(name) {
      if (name) {
        let nameArr = name.split(' ');
        return nameArr[1];
      }
    },
    truncatedText(text, length) {
      if (text) {
        if (!length || length < 0) {
          length = text.length;
        }
        if (text.length > length) {
          return `${text.substring(0, length)}...`
        }
        return text;
      }
    },
    enableFullscreenMode(bool) {
      this.fullscreenMode = bool
    },
    generateLinks(text) {
      return ChatHelper.createLinks(text);
    },
    // _stopBroadcastsCheck() {
    //   this.isBroadcastsCheckEnabled = false;
    // },
    // async _sleep(milliseconds) {
    //   await new Promise(resolve => {
    //     setTimeout(() => {
    //       resolve();
    //     }, milliseconds);
    //   });
    // },
    // async _startBroadcastsCheck() {
    //   while (this.isBroadcastsCheckEnabled) {
    //     await this._broadcastsCheck();
    //   }
    // },
    // async _broadcastsCheck() {
    //   if (!this.attachedContacts || !this.attachedContacts.length) {
    //     this.broadcasts = [];
    //     await this._sleep(BROADCASTS_CHECK_INTERVAL);
    //     return;
    //   }
    //
    //   const attachedContacts = this.attachedContacts;
    //   const eventId = Number(this.$route.params.eventId);
    //
    //   for (const attachedContact of attachedContacts) {
    //     const { contact } = attachedContact;
    //     const broadcastUrl = MeetingRoomsHelper.getBroadcastUrl({
    //       type: MeetingRoomType.BROADCAST,
    //       eventId: Number(this.$route.params.eventId),
    //       contactId: contact.id,
    //     });
    //
    //     let checkResult;
    //     try {
    //       checkResult = await axios({method: 'GET', url: broadcastUrl, timeout: 2000});
    //     } catch { /* do nothing */ }
    //
    //     if (!this.isBroadcastsCheckEnabled) {
    //       this.broadcasts = [];
    //       return;
    //     }
    //     if (attachedContacts !== this.attachedContacts || eventId !== Number(this.$route.params.eventId)) {
    //       /* something has changed */
    //       this.broadcasts = [];
    //       await this._sleep(BROADCASTS_CHECK_INTERVAL);
    //       return;
    //     }
    //
    //     const broadcastUrlIndex = this.broadcasts.indexOf(broadcastUrl);
    //     if (checkResult) {
    //       if (broadcastUrlIndex < 0) {
    //         this.broadcasts.push(broadcastUrl);
    //       }
    //     } else {
    //       if (broadcastUrlIndex > -1) {
    //         this.broadcasts.splice(broadcastUrlIndex, 1);
    //       }
    //     }
    //     await this._sleep(BROADCASTS_CHECK_INTERVAL);
    //   }
    // },
  }
};
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  z-index: 99;
  top: 60px;
  left: 60px;
  width: calc(100% - 60px - 60px); // Because of the new menu and BAD stacking context planning
  height: calc(100% - 60px);
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.6s;
  padding: 200px;

  pointer-events: none;

  .company-mediablock {
    width: 100%;
  }

}

.overlay img {
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
  transform: scale(0.95);
  transition: transform 0.3s;
}

.overlay-visible {
  visibility: visible;
  outline: none;
  cursor: default;
  opacity: 1;
  pointer-events: all;
}

.overlay-visible img {
  transform: scale(1);
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 100px;
  cursor: pointer;
}

.flex-row {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.company-wrapper {
  width: 100%;
  min-width: 392px; // AW-734
  height: auto;
  box-shadow: 5px 5px 15px #3754aa1a;
  border-radius: 10px;
  background-color: $ew-white;
  z-index: 1;
}

.truncated-text {
  height: 95px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.company {
  padding: 0 25px;

  .head-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .avatar {
      width: 90px;
      height: 90px;
      min-width: 90px;
      border-radius: $ew-main-border-radius;
      position: relative;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .title {
      text-align: left;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      margin-left: 20px;
      word-break: break-word;
      width: 100%;
      max-width: 100%;
      text-overflow: ellipsis;

      .max-width {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .location,
      .website {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
      }

      .website {
        word-break: break-word;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
          color: $ew-blue;
        }
      }
    }
  }


  .nav {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;

    .tab {
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      color: $ew-black;
      text-decoration: none;
      opacity: 0.8;
    }

    .highlight {
      border-bottom: 1px solid $ew-blue;
      color: $ew-blue;
    }

  }

  .content {
    margin-top: 10px;
    margin-bottom: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .description {
      font-size: 12px;
      line-height: 14px;
      text-align: left;
    }
  }
}

html[lang=ru] .nav a {
  font-size: 12px;
}

.field-block {
  padding: 10px 0;
  position: relative;
  overflow: hidden;

  &-documents {
    padding-left: 25px;
    padding-right: 25px;
  }

  .field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px 8px;

    .title {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      color: $ew-black;
      opacity: 0.8;
      position: relative;

      &::before {
        display: block;
        position: absolute;
        left: -300%;
        bottom: 2px;
        content: "";
        border-bottom: 1px solid #eaeaea;
        width: 300%;
      }
    }

    .btn {
      text-align: left;
      font-size: 12px;
      color: #00b6f8;
      display: block;
    }
  }

  .enumerable {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    overflow: hidden;
    height: auto;
  }

  .flex-column-center {
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
  }

  .field-content {
    padding: 16px 25px;

    .square-radius {
      border-radius: 5px;
    }

    .round-radius {
      border-radius: 50%;
    }

    .content-item {
      margin-bottom: 26px;
      width: 25%;
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      min-height: 120px;

      &:hover {
        background-color: darken($ew-gray-lightest, 1%);
      }
    }

    .avatar {
      width: 60px;
      height: 60px;
      min-width: 60px;
      position: relative;
      overflow: hidden;
      margin-bottom: 8px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .title {
      margin-top: 6px;
    }

    .title,
    .subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $ew-black;
      opacity: 0.8;
      word-wrap: break-word;
      width: 100%;
      max-width: 70px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    .team-member { // TODO: reuse styles. Duplicates styles in edit-company.vue
      width: 25%;
      text-align: center;
      word-wrap: break-word;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 14px;

      &-info {
        text-align: center;

        .team-member-name-part {
          margin-bottom: 6px;
        }

        .team-member-job-part {
          font-weight: 300;
          color: $ew-gray;
        }
      }
    }
  }
}

.field-block-notes {
  padding-left: 25px;
  padding-right: 25px;
}

.company-wrapper-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
</style>

<style lang="scss">
.read-more {
  font-size: 12px;
  color: $ew-blue !important;
  text-decoration: none;
  cursor: pointer;
}

.more-btn {
  text-align: left;
  font-size: 12px;
  color: $ew-blue !important;
}
</style>
